import MessageIdFragment from "./MessageIdFragment.gql";
import MessageThreadFragment from "./MessageThreadFragment.gql";

/**
 * @param {import("@apollo/client").ApolloClient} apolloClient
 * @param {Object} message
 * @param {Number|String} messageThreadId
 * @description
 * Can be used for mutations and subscriptions to add new message in the thread cache.
 * A guard keeps it from adding the message to the cache more than once
 * @returns
 */
export const addMessageToThreadCache = (
  apolloClient,
  message,
  messageThreadId
) => {
  // TODO - check what to do with this cache checking. because message was always cached already at the moment of checking.
  // const isMessageCached = apolloClient.readFragment({
  //   id: `Message:${message.id}`,
  //   fragment: MessageIdFragment,
  // });
  // if (isMessageCached) {
  //   return;
  // }

  const id = `MessageThread:${messageThreadId}`;
  const fragment = MessageThreadFragment;

  const messageThread = apolloClient.readFragment({ id, fragment });
  const { messages = [] } = messageThread;

  if (!messageThread || messages.find((x) => x.id === message.id)) {
    return;
  }

  apolloClient.writeFragment({
    id,
    fragment,
    data: {
      ...messageThread,
      messages: [...messages, message],
    },
  });
};
